
.background-default{
  background: linear-gradient(to right, #8ad7e6 0%, #0099cc 100%);
  color: #FFF;
  cursor: "pointer";
}

.background-semaforo-excelente {
  background: linear-gradient(to right, #06ee35 0%, #2a9e3a 100%);
  color: #FFF; 
  cursor: pointer; 
}

.background-semaforo-bueno {
  background: linear-gradient(to right, #faab27 0%, #a75500 100%);
  color: #FFF; 
  cursor: pointer; 
}

.background-semaforo-malo {
  background: linear-gradient(to right, #ee0606 0%, #9e2a2a 100%); 
  color: #FFF; 
  cursor: pointer; 
}

/*
.color-hover-nuevos:hover {
  background: linear-gradient(to right, #8ad7e6 0%, #006283 100%); 
  color: #FFF; 
  cursor: pointer; 
}

.color-hover-aprobados:hover {
  background: linear-gradient(to right, #06ee35 0%, #2a9e3a 100%);
  color: #FFF; 
  cursor: pointer; 
}

.color-hover-negociacion:hover {
  background: linear-gradient(to right, #faab27 0%, #a75500 100%);
  color: #FFF; 
  cursor: pointer; 
}

.color-hover-entrevistados:hover {
  background: linear-gradient(to right, #d4d4ed 0%, #1100a7 100%); 
  color: #FFF; 
  cursor: pointer; 
}

.color-hover-enviados:hover {
  background: linear-gradient(to right, #f4fa27 0%, #9e822a 100%); 
  color: #FFF; 
  cursor: pointer; 
}

.color-hover-rechazados:hover {
  background: linear-gradient(to right, #ee0606 0%, #9e2a2a 100%); 
  color: #FFF; 
  cursor: pointer; 
}
*/