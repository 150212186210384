/**=====================
     01. General CSS Start
==========================**/
//Custom css Start
body {
    font-size: $body-font-size;
    overflow-x: hidden;
    font-family: $font-rubik, $font-serif;
    color: $theme-body-font-color;
}
ul {
    padding-left: $ul-padding-left;
    list-style-type: none;
    margin-bottom: 0;
    padding-right: $ul-padding-right;
}
* a {
    color: $primary-color;
    text-decoration: none;
    letter-spacing: 1px;
}
*.btn:focus {
    box-shadow: $btn-focus-box-shadow !important;
}
p {
    font-size: $paragraph-font-size;
    line-height: $paragraph-line-height;
    letter-spacing: $paragraph-letter-spacing;
}
code {
    color: $code-tag-color !important;
    background-color: $code-tag-bg-color;
    padding: $code-tag-padding;
    margin: $code-tag-margin;
    border-radius: $code-tag-border-radious;
}
blockquote {
    border-left: $blockquote-border;
    padding: $blockquote-padding;
}
blockquote {
    &.text-center {
        border: none;
        padding: $blockquote-padding;
    }
}
blockquote {
    &.text-right {
        border-left: none;
        border-right: $blockquote-border;
        padding: $blockquote-padding;
    }
}
:focus {
    outline-color: $all-focus-outline-color;
}
.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
svg {
    vertical-align: baseline;
}
input {
    &:focus {
        outline-color: $transparent-color;
    }
}
label {
    margin-bottom: 0.5rem;
}
.media-widgets {
    .media-body {
        margin-bottom: 30px;
    }
}
//Custom css End

//Typography css start

.typography {
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        margin: 20px 0;
        margin-top: 0;
        &:last-child {
            margin-bottom: 0;
        }
    }
    small {
        padding-left: $small-tag-padding;
        color: $small-tag-color;
    }
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    line-height: 1.2;
}
.h1 {
    font-size: 2.5rem;
}
.h2 {
    font-size: 2rem;
}
.h3 {
    font-size: 1.75rem;
}
.h4 {
    font-size: 1.5rem;
}
.h5 {
    font-size: 1.25rem;
}
.h6 {
    font-size: 1rem;
}
pre {
    background-color: $pre-tag-bg-color;
    padding: $pre-tag-padding;
}
a {
    &:hover {
        text-decoration: none;
        color: $primary-color;
    }
}
//Typography css end

.owl-theme {
    .owl-dots {
        .owl-dot {
            span {
                background: lighten($primary-color, 20%);
            }
            &.active,
            &:hover {
                span {
                    background: $primary-color;
                }
            }
        }
    }
    .owl-nav {
        &.disabled {
            & + .owl-dots {
                margin-bottom: -10px;
                margin-top: 25px;
            }
        }
    }
}

.click2edit {
    & ~ .note-editor {
        &.note-frame {
            margin-bottom: 30px;
        }
    }
}
.note-editor {
    &.note-frame {
        border-color: $light-color;
    }
}
.note-editor {
    &.note-frame {
        border-color: $light-color;
    }
}
// modal page
.modal {
    .modal-body {
        .card {
            box-shadow: none !important;
        }
    }
}
.modal-dialog {
    .modal-content {
        .modal-body {
            p {
                a {
                    margin-right: 0;
                }
            }
        }
    }
}
// index page notify
.alert-theme {
    button {
        top: 33px !important;
        right: 30px !important;
        color: $primary-color;
    }
    span {
        + span {
            + span {
                // border: 2px solid $primary-color;
                background-color: lighten($primary-color, 40%);
                color: $primary-color;
                border-radius: 4px;
                box-shadow: 0 2px 14px -3px rgba($black, 0.2);
            }
        }
    }
    i {
        padding: 20px;
        padding-right: 0 !important;
        margin-right: 20px !important;
    }
    .progress {
        margin-top: -2px !important;
        height: 2px;
        .progress-bar {
            background-color: $primary-color;
        }
    }
}
.alert-copy {
    border: 1px solid $primary-color;
    line-height: 1;
    display: inline-block;
    width: 300px;
    color: $white;
    background-color: $primary-color;
    .close {
        box-shadow: none;
        color: $white;
        line-height: 1.4;
        font-weight: 100;
    }
}

.ace-editor {
    height: 400px;
    width: 100%;
}
.editor-toolbar {
    width: 100% !important;
}
.CodeMirror {
    top: 0 !important;
}
.CodeMirror-fullscreen {
    top: 50px !important;
    z-index: 9;
}

// rangeslider
.irs-from,
.irs-to,
.irs-single {
    background: $primary-color;
    &:after {
        border-top-color: $primary-color;
    }
}
.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge {
    background-color: $primary-color;
}

// sweetalert css
.swal-button {
    background-color: $primary-color;
    &:active {
        background-color: darken($primary-color, 5%);
    }
}
.swal-button--cancel {
    background-color: #efefee !important;
}
.clipboaard-container {
    h6 {
        line-height: 1.6;
    }
}

fieldset {
    .bootstrap-touchspin-postfix,
    .bootstrap-touchspin-prefix {
        border-radius: 0;
        padding: 0.4rem 0.75rem;
    }
    .input-group-prepend {
        & ~ .form-control {
            height: 39px;
        }
    }
    .input-group-lg {
        .input-group-prepend,
        .input-group-append {
            .btn {
                padding: 0.8rem 1.75rem;
            }
        }
    }
}
.f1 .f1-steps .f1-step .f1-step-icon {
    padding: 12px;
}
.form-inline .form-group {
    display: flex;
}
.input-group-text {
    i {
        line-height: 1.5;
    }
}

// contact
.contact-profile-form {
    padding-bottom: 30px;
}
.contact-search {
    .form-group {
        width: 100%;
        .form-control {
            height: 35px;
        }
        &:before {
            left: 67px !important;
            top: 5px !important;
        }
        &:after {
            left: 37px !important;
            top: 8px !important;
        }
    }
}
.contact-filter {
    display: flex;
    align-items: center;
    .input-range,
    h5 {
        margin-top: -15px;
    }
    h5 {
        width: 224px;
    }
}
.contact-table {
    table {
        th,
        td {
            border-top: none;
            padding: 30px;
            vertical-align: middle;
        }
        tr {
            td {
                border-bottom: 1px solid $light-semi-gray;
                h6 {
                    margin-bottom: 0;
                }
                .btn-primary {
                    color: $white;
                    &:hover {
                        color: $white;
                    }
                }
            }
            &:last-child {
                td {
                    border-bottom: none;
                }
            }
        }
    }
}
.change-img-button {
    margin: 0 auto;
}
.contact-profile {
    position: relative;
    .icon-wrapper {
        position: absolute;
        top: 73%;
        left: 53%;
        padding: 10px;
        background-color: #fff;
        border-radius: 25px;
        cursor: pointer;
    }
}
.upload {
    position: absolute;
    width: 36px;
    left: 0;
    right: 0;
    opacity: 0;
}

.search-not-found {
    align-items: center;
    justify-content: center;
    height: 631px;
    display: flex;
    .second-search {
        width: 200px;
    }
}
// tap top
.tap-top {
  text-align: center;
  cursor: pointer;
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 5;
  color: #fff;
  background: $primary-color;
  border: none;
  padding: 7px;
  box-shadow: 0 0 10px $primary-color;
  opacity: 0.5;
  transition: all 0.3s ease;
  &:hover{
    transition: all 0.3s ease;
    opacity: 1;
  }
  svg{
    width: 20px;
  }
}



/**=====================
      01. General CSS Ends
==========================**/
