.my-gallery {
  padding-right: 0;
  margin-bottom: -30px;
  img {
    width: 100%;
  }
  figure {
    margin-bottom: 30px;
  }
  figcaption {
    display: none;
  }
  &.gallery-with-description {
    img {
      padding: 10px !important;
      border: 1px solid #ddd !important;
      border-bottom: none !important;
      border-radius: 0;
    }
    a {
      > div {
        border-top: none !important;
        margin-bottom: 0;
        padding: 5px 10px 10px 10px;
      }
    }
    h4 {
      margin-top: 0px;
    }
  }
  &.gallery {
    figure {
      &.img-hover {
        a {
          > div {
            overflow: hidden;
          }
        }
        &.hover-12 {
          a {
            > div {
              background: #158df7;
              &:hover {
                img {
                  opacity: 0.7;
                }
              }
            }
          }
        }
      }
    }
  }
}
