.fixed-div {
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    background-color: #FF6600;
    color: #FFF;
    padding: 10px;
    text-align: center;
    z-index: 1000; /* Asegura que esté encima de otros elementos */
}

.fixed-div a{
    color: #666;
    font-weight: bold;
    cursor: pointer;
}

.text-bold{
    font-weight: bold;
}