.custom-row-table{
    overflow-x: auto !important; 
}
.custom-col-table{
    overflow-x: auto !important; 
}
.custom-card-table {
    overflow-x: auto !important; 
}
.custom-card-body-table{
    overflow-x: auto !important; 
}

.table-responsive {
    overflow-x: auto !important; /* Permite que la tabla sea desplazable horizontalmente si es necesario */
    max-width: 100% !important; /* Ajusta la tabla al 100% del ancho disponible */
}
  
/* Estilo opcional para permitir que el contenido de las celdas se ajuste automáticamente */
.table-responsive div{
    table-layout:auto  !important; /* Ajusta el tamaño de la celda al contenido */
    white-space:normal  !important; /* Evita que el texto se divida en varias líneas */
    width: 100%;
}

.data-table-header {
  text-align: center; /* Centrar el texto horizontalmente */
}

.toggle-container {
    position: relative;
  }

  .btn-search{
    margin-bottom: 20px;
  }
  
  .content {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  }
  
  .visible {
    max-height: 200px; /* Altura máxima del div visible */
    opacity: 1;
  }
  
  .hidden {
    max-height: 0;
    opacity: 0;
  }
  .btn{
    cursor: pointer;
  }

  .title{
    font-weight: 500;
  }

  .custom-button {
    display: inline-block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
  
  .custom-button input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .custom-modal {
    max-width: 80%; /* Ancho máximo personalizado */
    max-height: 80vh; /* Altura máxima personalizada (el 80% del alto de la ventana) */
  }

  @media (min-width: 992px) {
    .modal-lg, .modal-xl {
        max-width: 1530px;
    }
  }



  .text-through{
    text-decoration: line-through;
  }