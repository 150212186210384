/**=====================
     62. Responsive CSS Start
==========================**/

/* ========= min and max scss for 1200 to 1366 screen ========= */

@media screen and (max-width: 1660px) {
  .photos {
    ul {
      li {
        width: 26%;
      }
    }
  }
  .user-status {
    table {
      thead {
        tr {
          th {
            &:nth-child(3) {
              min-width: 124px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:first-child {
              min-width: 278px;
            }
          }
        }
      }
    }
  }
  .bar-chart {
    canvas {
      width: 100% !important;
    }
  }
  .main-header-right {
    .main-header-right {
      .left-menu-header {
        .orm-control-plaintext {
          .form-inline {
            width: auto !important;
          }
        }
      }
    }
  }
  .chat-box {
    .chat-history {
      .call-icons {
        ul {
          li {
            border: 1px solid $dark-gray;
          }
        }
      }
      .total-time {
        h2 {
          color: $dark-gray;
        }
      }
      .call-content {
        .btn {
          width: auto;
          margin: 0 auto;
        }
      }
    }
  }
  .chat-left-aside {
    .people-list {
      height: 520px;
    }
  }
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-msg-box {
          height: 440px;
        }
      }
    }
    .people-list {
      ul {
        max-height: 450px;
        overflow: auto;
      }
    }
  }
  .call-chat-body {
    .chat-box {
      .people-list {
        ul {
          max-height: 550px;
          overflow: auto;
        }
      }
      overflow: hidden;
      height: 625px;
    }
  }
  .caller-img {
    position: absolute;
    width: 100%;
    max-width: 100%;
    left: 15px;
    img {
      opacity: 0.7;
    }
  }
  .chat-box {
    .chat-history {
      .receiver-img {
        margin-top: 25px;
      }
      .call-content {
        > div {
          height: 545px;
          z-index: 7;
          background-color: rgba(255, 255, 255, 0.75);
          background-blend-mode: overlay;
          width: 100%;
          padding: 30px;
        }
      }
    }
  }
  // timeline small page
  .timeline-small {
    .media {
      .timeline-round {
        &.timeline-line-1 {
          &:after {
            bottom: -78px;
          }
        }
      }
    }
  }
  // ecommerce app
  .new-products {
    .product-box {
      .product-details {
        padding: 15px;
      }
    }
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1440px) {
  // product page
  .product-filter {
    &.new-products {
      .product-box {
        > div {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .product-img {
          img {
            width: 100px;
            margin: 0 auto;
          }
        }
        .product-details {
          padding: 15px;
          text-align: center !important;
        }
      }
    }
  }
}
@media (max-width: 1280px) {
  .box-layout.page-wrapper.horizontal-wrapper {
    .page-main-header {
      padding-left: unset;
      max-width: unset;
      left: unset;
    }
    .page-body-wrapper {
      width: unset;
    }
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1660px) {
  .xl-none {
    display: none;
  }
  .xl-60 {
    max-width: 60%;
    flex: 0 0 60%;
  }
  .xl-50 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .xl-100 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .xl-23 {
    max-width: 23%;
    flex: 0 0 23%;
  }
  .xl-25 {
    max-width: 25%;
    flex: 0 0 25%;
  }
  .xl-40 {
    max-width: 40%;
    flex: 0 0 40%;
  }
  .xl-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1366px) {
  .vertical-menu-mt {
    .crm-activity {
      height: 298px;
    }
  }
  .flot-chart-container-small {
    height: 227px;
  }
  .call-chat-sidebar {
    max-width: 270px;
  }
  .product-box {
    .product-details {
      padding: 15px;
      p {
        margin-bottom: 10px;
      }
    }
  }
  .flot-chart-container {
    height: 282px;
  }
  .custom-card {
    .card-profile {
      img {
        height: 115px;
      }
    }
    .profile-details {
      h4 {
        font-size: 18px;
      }
      h6 {
        margin-bottom: 24px;
      }
    }
    .card-footer {
      > div {
        h3 {
          font-size: 18px;
        }
      }
    }
  }
  .crm-activity {
    height: 291px;
    overflow-y: auto;
    .media {
      .media-body {
        .dates {
          display: flex;
        }
      }
    }
  }
  .calender-widget {
    .cal-date {
      width: 95px;
      height: 95px;
      h5 {
        line-height: 1.4;
        padding: 13px;
      }
    }
  }
  // chat
  .chat-body {
    padding: 10px;
  }
  .chat-box {
    .chat-history {
      .call-content {
        .btn {
          font-size: 15px;
        }
      }
      .total-time h2 {
        font-size: 30px;
      }
      .receiver-img {
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .call-icons {
        margin-top: 20px;
        margin-bottom: 20px;
        ul {
          li {
            width: 50px;
            height: 50px;
            padding: 7px;
          }
        }
      }
    }
    .user-image {
      width: 30px;
      height: 30px;
      margin-top: 3px;
      margin-right: 3px;
    }
    .about {
      margin-top: 0;
    }
    .border-tab.nav-tabs {
      .nav-item {
        a {
          padding: 13px 4px !important;
          color: $semi-dark !important;
          letter-spacing: 0;
          font-size: 12px !important;
          line-height: 6;
        }
      }
    }

    .chat-menu {
      .people-list {
        ul.list {
          max-height: 376px;
          overflow-y: auto;
        }
      }
      .user-profile {
        .image {
          .avatar {
            img {
              width: 100px;
              height: 100px;
            }
          }
          .icon-wrapper {
            width: 30px;
            height: 30px;
            font-size: 13px;
          }
        }
      }
    }
  }

  .chat-left-aside {
    .people-list {
      height: 420px;
      ul {
        max-height: 376px;
        overflow-y: auto;
      }
    }
    .status-circle {
      top: 4px;
      left: 36px;
    }
  }
  //blog scss
  .blog-box {
    .blog-details-main {
      .blog-bottom-details {
        margin: 20px 10px;
      }
      .blog-social {
        li {
          padding: 0px 10px;
        }
      }
    }
  }
  .blog-bottom-content {
    display: none;
  }
  .blog-box.blog-shadow {
    .blog-details {
      padding: 10px;
    }
  }
  .browser-widget {
    img {
      height: 50px;
    }
  }

  // email-app
  .email-wrap {
    .email-left-aside {
      .email-app-sidebar {
        .media {
          display: block;
          .media-size-email {
            width: 100%;
            text-align: center;
            .mr-3 {
              margin-right: 0 !important;
            }
          }
          .media-body {
            text-align: center;
          }
        }
      }
    }
    .email-right-aside {
      .email-body {
        .attachment {
          ul {
            li {
              img {
                width: 73px;
                height: 73px;
              }
            }
          }
        }
        .email-top {
          line-height: 50px;
          .float-right {
            display: none !important;
          }
          h5 {
            padding: 13px 0;
          }
          .dropdown-toggle {
            background-color: $transparent-color !important;
            border-color: $transparent-color !important;
            color: #6c757d !important;
          }
        }
        .inbox {
          max-height: 668px;
          overflow: auto;
          .media {
            display: block;
            label {
              text-align: center;
            }
            .media-size-email {
              width: 100%;
              text-align: center;
              .mr-3 {
                margin-right: 0 !important;
              }
            }
          }
          .media-body {
            text-align: center;
            h6 {
              font-size: 14px;
            }
          }
        }
        .email-compose {
          .cke_contents.cke_reset {
            max-height: 144px;
            width: 99%;
          }
          .compose-border {
            margin: 0;
          }
        }
      }
    }
  }

  // alert page
  .alert-dismissible {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 181px;
    }
  }
  // touchspin page
  .bootstrap-touchspin {
    .touchspin-vertical-tab {
      .input-group {
        width: 96% !important;
      }
    }
  }
}
/* ========= min and max scss for 992 to 768 screen ========= */

/* ========= responsive scss for 1366 screen ========= */
@media only screen and (max-width: 1366px) {
  // product page
  .grid-options {
    ul {
      li {
        &:last-child {
          display: none;
        }
      }
    }
  }
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        .product-img {
          width: 25%;
        }
      }
    }
  }
  // contact
  .contact-search {
    .form-group {
      .form-control {
        margin: 0;
      }
    }
  }
  .contact-filter {
    margin-top: 20px;
  }
  .contact-table {
    table {
      tr {
        td {
          &:last-child {
            min-width: 273px;
          }
        }
      }
    }
  }
  // timepicker
  .time_picker_wrapper {
    width: 56%;
  }
  // cart page
  .cart {
    .qty-box {
      width: 40%;
    }
  }
  // alert page
  .alert-dismissible {
    strong {
      & ~ p {
        display: inline;
      }
    }
  }

  // timeline small page
  .timeline-small {
    .media {
      .media-body {
        p {
          width: 95%;
        }
      }
    }
  }
  // chat page
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-msg-box {
          height: 364px;
          .chat-user-img {
            margin-top: -30px;
          }
          .message {
            width: 100%;
            padding: 15px;
          }
        }
      }
    }
  }
  .chat-right-aside {
    flex: 0 0 60%;
    max-width: 60%;
    overflow: hidden;
    .chat {
      .chat-message {
        .smiley-box {
          width: 45px;
        }
      }
    }
  }
  .chat-box {
    .chat-history {
      .receiver-img {
        img {
          height: 150px;
        }
      }
    }
  }
  .call-chat-body {
    .chat-box {
      overflow: hidden;
      height: 565px;
    }
  }
  .calender-widget {
    .cal-desc {
      p {
        max-height: 88px;
        overflow: auto;
      }
    }
  }

  .equal-height-xl {
    height: 517px;
  }

  .authentication-main {
    .auth-innerright {
      background-size: cover;
    }
  }
  .grp-btns {
    display: inline-block;
  }
  //ecommerce dashboard
  .ecommerce-widget {
    .total-num {
      font-size: 23px;
      letter-spacing: 0px;
    }
  }
  .support-ticket-font {
    .total-num {
      font-size: 22px;
    }
  }
  //default dashboard
  .custom-card {
    padding: 15px;
    .card-social {
      li {
        a {
          padding: 12px;
        }
      }
    }
  }
  //chart widget
  .status-details {
    h3 {
      font-size: 18px;
    }
  }
  .chart-widget-top {
    .total-value {
      font-size: 20px;
    }
  }
  .status-widget {
    .card-body {
      padding: 10px;
    }
  }
  //general widget
  .user-status {
    table {
      thead {
        tr {
          th {
            &:nth-child(3) {
              min-width: unset;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:first-child {
              min-width: unset;
            }
          }
        }
      }
    }
  }
  .browser-widget {
    .media {
      flex-wrap: wrap;
      .media-body {
        h3 {
          font-size: 20px;
        }
        p {
          margin-bottom: 2px;
        }
        h4 {
          font-size: 20px;
        }
      }
    }
  }
  .testimonial {
    i {
      font-size: 46px;
    }
    p {
      margin-top: 20px;
    }
  }
  .cal-date-widget .datepicker {
    margin: 0 auto;
    width: unset;
  }
  .static-top-widget {
    .media-body {
      h3 {
        font-size: 30px;
      }
      .icon-bg {
        right: -21px;
      }
    }
  }
  .widget-joins {
    .media {
      padding: 20px;
      .media-body {
        h3 {
          font-size: 20px;
        }
      }
    }
  }
  .redial-social-widget {
    width: 125px;
    height: 125px;
    i {
      width: 100px;
      height: 100px;
      font-size: 35px;
    }
  }

  //chat
  .chat-body {
    padding: 10px;
  }
  .chat-box {
    .chat-history {
      .call-content {
        .btn {
          font-size: 15px;
        }
      }
      .total-time h2 {
        font-size: 30px;
      }
      .receiver-img {
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .call-icons {
        margin-top: 20px;
        margin-bottom: 20px;
        ul {
          li {
            width: 50px;
            height: 50px;
            padding: 7px;
          }
        }
      }
    }
    .user-image {
      width: 45px;
      height: 45px;
    }
    .about {
      margin-top: 0;
    }
    .border-tab.nav-tabs {
      .nav-item {
        a {
          padding: 13px 4px !important;
          color: $semi-dark !important;
          letter-spacing: 0;
          font-size: 12px !important;
          line-height: 6;
        }
      }
    }
    .chat-menu {
      .people-list {
        ul.list {
          max-height: 376px;
          overflow-y: auto;
        }
      }
      .user-profile {
        margin-top: 0;
        .image {
          .avatar {
            img {
              width: 100px;
              height: 100px;
            }
          }
          .icon-wrapper {
            width: 30px;
            height: 30px;
            font-size: 13px;
          }
        }
        .user-content {
          p {
            font-size: 14px;
            margin-bottom: 10px;
          }
          h5 {
            margin: 20px 0;
          }
          hr {
            margin: 20px 0;
          }
        }
        .follow {
          .follow-num {
            font-size: 20px;
          }
        }
      }
    }
  }

  .chat-left-aside {
    .people-list {
      height: 490px;
      ul {
        max-height: 434px;
        overflow-y: auto;
      }
    }
  }
  .status-circle {
    top: 4px;
    left: 37px;
  }
  // touchspin page
  .bootstrap-touchspin {
    .dropdown-basic {
      .dropdown {
        .dropdown-content {
          left: -21px;
        }
      }
    }
  }
  // forget password page
  .reset-password-box {
    .theme-form {
      .form-group {
        .btn {
          padding: 6px 22px;
        }
      }
    }
  }
}

/* ========= responsive scss for 1200 screen ========= */
@media only screen and (max-width: 1199px) {
  .alert-theme {
    max-width: 400px;
  }

  .vertical {
    .page-main-header {
      .main-header-right {
        .vertical-mobile-sidebar {
          display: block;
        }
      }
    }
  }
  .mega-menu-header {
    .main-header-right {
      .vertical-mobile-sidebar {
        display: block;
        margin-right: 15px;
      }
    }
  }
  // kanban board
  .jkanban-container {
    .kanban-container {
      display: block;
      .kanban-board {
        width: 400px;
        .kanban-drag {
          > div {
            > div {
              display: block;
              white-space: unset;
              div[data-testid="lane"] {
                margin-left: 0;
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
  // header sidebar css
  .left-menu-header {
    .mega-menu-container {
      position: fixed;
      width: 300px;
      border: 1px solid #eee;
      top: 0px;
      height: 100vh;
      left: unset;
      right: -300px;
      z-index: 99;
      transition: all 0.3s ease;
      overflow: scroll;
      .mega-box {
        flex-basis: auto;
      }
      &.d-block {
        right: 0;
        transition: all 0.3s ease;
        animation: fadeInRight 0.3s ease-in-out;
      }
    }
  }
  .link-section {
    > div {
      > h6 {
        &:before {
          content: "\f105";
          font-family: $font-awesome;
          position: absolute;
          right: 30px;
          font-size: 18px;
          transition: content 0.3s ease;
        }
      }
    }
  }
  .page-wrapper {
    .page-main-header {
      .main-header-right {
        .nav-right {
          position: unset;
          padding: 0;
          > ul {
            li {
              .submenu {
                li {
                  &:first-child {
                    padding-left: 15px !important;
                  }
                }
              }
            }
          }
        }
        .mobile-sidebar {
          .switch-sm {
            .switch {
              margin-top: 6px;
            }
          }
        }
      }
    }
  }
  // product page
  .feature-products {
    .filter-toggle {
      padding: 8px 15px;
      background-color: $white;
      margin-left: 10px;
      border-radius: 5px;
    }
    span {
      &.f-w-600 {
        padding-top: 8px;
        display: inline-block;
      }
    }
  }
  .d-none-productlist {
    display: block;
    margin-right: 10px;
  }
  .product-sidebar {
    transform: translateX(-300px) scaleX(0);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    height: 0;
    position: absolute;
    z-index: 7;
    width: 300px;
    &.open {
      transform: translateX(0px) scaleX(1);
      box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
      visibility: visible;
      opacity: 1;
      height: auto;
      top: 0;
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 7px solid $transparent-color;
        border-right: 7px solid $transparent-color;
        border-bottom: 7px solid $white;
        right: 120px;
        top: -7px;
      }
    }
    .filter-section {
      .card-header {
        display: none;
      }
    }
  }
  .product-wrapper {
    &.sidebaron {
      .product-grid {
        .product-wrapper-grid {
          margin-left: 0;
        }
      }
      .product-sidebar {
        .filter-section {
          .card {
            .left-filter {
              top: unset;
            }
          }
        }
      }
    }
    .product-sidebar {
      .filter-section {
        .card {
          .left-filter {
            opacity: 1;
            height: auto;
            visibility: visible;
          }
        }
      }
    }
  }
  // social app page
  .photos {
    ul {
      li {
        width: auto;
        &:nth-child(3n) {
          margin-right: 15px;
        }
      }
    }
  }
  .user-profile {
    .hovercard {
      .user-image {
        .share-icons {
          position: absolute;
          right: 45px;
          top: -22px;
          li {
            .social-icon {
              width: 40px;
              height: 40px;
              i {
                font-size: 18px;
              }
            }
            color: #fff;
            font-size: 25px;
          }
        }
      }
    }
  }
  // timeline small page
  .timeline-small {
    .media {
      .timeline-round {
        &.timeline-line-1 {
          &:after {
            height: 33px;
            bottom: -52px;
          }
        }
        &.small-line {
          &:after {
            height: 18px;
            bottom: -34px;
          }
        }
        &.medium-line {
          &:after {
            height: 30px;
            bottom: -52px;
          }
        }
      }
      .media-body {
        p {
          width: 100%;
        }
      }
    }
  }
  .lg-mt-col {
    margin-top: 30px;
  }
  .xl-none {
    display: none;
  }
  .user-status {
    table {
      tbody {
        tr {
          td {
            .d-inline-block {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .lg-mt {
    margin-top: 30px;
  }
  .product-page-details {
    margin-top: 30px;
  }
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        .product-img {
          width: 32%;
        }
      }
    }
  }
  .debit-card {
    order: 1;
  }
  .typography {
    .row {
      div {
        & + div {
          margin-top: 30px;
        }
      }
    }
  }
  .listing {
    .card-body {
      .row {
        > div {
          & + div {
            margin-top: 30px;
          }
        }
      }
    }
  }
  .flot-chart-container {
    height: 250px;
  }
  .error-wrapper {
    .maintenance-icons {
      li {
        i {
          color: $light-color;
          font-size: 40px;
        }
        &:nth-child(2) {
          i {
            font-size: 80px;
            margin-left: 90px;
          }
        }
        &:nth-child(3) {
          i {
            font-size: 120px;
            margin-top: -75px;
            margin-right: 110px;
          }
        }
      }
    }
  }
  .jvector-map-height {
    height: 280px;
  }
  .blog-list {
    .blog-details {
      p {
        margin-bottom: 5px;
      }
    }
  }
  .custom-card {
    .card-profile {
      img {
        height: 135px;
      }
      .card-footer {
        > div {
          h3 {
            font-size: 18px;
          }
        }
      }
    }
  }
  .equal-height-lg {
    min-height: unset !important;
  }
  //mega menu
  .mega-menu {
    .onhover-show-div {
      height: 435px;
      overflow-x: scroll;
      left: 215px;
    }
    .mega-bg {
      display: none;
    }
    .lg-mt {
      margin-top: 25px;
    }
  }
  //blog
  .blog-single {
    .comment-box {
      ul {
        .comment-social {
          margin-left: 0;
        }
      }
    }
  }
  //error and maintenace
  .error-wrapper {
    .maintenance-heading {
      margin-top: 30px;
      .headline {
        font-size: 50px;
        margin-top: -15%;
        margin-bottom: 0px;
      }
      .cloud-second {
        margin-top: -25px;
      }
    }
  }
  // general widget
  .user-status {
    table {
      tbody {
        tr {
          td {
            &:first-child {
              min-width: 278px;
            }
          }
        }
      }
    }
  }
  .testimonial {
    p {
      margin-top: 17px;
    }
  }
  .contact-form {
    padding: 10px;
    .theme-form {
      .form-icon {
        margin-top: -57px;
      }
    }
  }
  .calender-widget {
    .cal-date {
      width: 90px;
      height: 90px;
      h5 {
        font-size: 16px;
        padding: 18px;
      }
    }
    .cal-desc {
      padding: 14px;
      p {
        max-height: 114px;
        overflow-y: auto;
      }
    }
  }
  .browser-widget {
    padding: 0px;
    .media {
      .media-body {
        h4 {
          font-size: 17px;
        }
      }
      .media-img {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
  //ecommerce dashboard
  .crm-overall {
    .overall-chart {
      height: 200px;
    }
  }
  //chat
  .chat-box {
    .chat-menu {
      padding-right: 0;
      .people-list {
        ul.list {
          max-height: 350px;
          overflow-y: auto;
        }
      }
    }
    .btn-lg {
      font-size: 12px;
    }
    .people-list {
      ul {
        height: auto;
      }
    }
    .chat-right-aside {
      .chat {
        .chat-header {
          height: auto;
          .chat-menu-icons li a i {
            font-size: 19px;
          }
        }
        .chat-message {
          margin: 0;
        }
      }
    }
    .chat-history {
      .total-time h2 {
        font-size: 25px;
      }
      .row {
        .col-sm-7 {
          padding-right: 0;
        }
      }
    }
    .border-tab.nav-tabs {
      width: 100%;
      .nav-item {
        margin-right: 5px;
      }
    }
    .chat-left-aside {
      .people-list {
        height: 493px;
      }
    }
  }
  .chat-menu-icons {
    display: block;
    clear: both;
    width: 100%;
    text-align: right;
  }
  .chat-menu {
    right: 0;
    border-top: 1px solid $light-semi-gray;
    opacity: 0;
    transform: translateY(-30px);
    visibility: hidden;
    top: 120px;
    position: absolute;
    z-index: 9;
    background-color: $white;
    transition: all linear 0.3s;
    &.show {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
      transition: all linear 0.3s;
      padding-bottom: 25px;
    }
  }
  .chat-history {
    height: 447px;
  }
  .chat-box {
    .chat-history {
      .call-content {
        > div {
          padding-top: 70px;
        }
      }
    }
    .toogle-bar {
      display: inline-block;
      margin-right: 0 !important;
    }
  }
  .chat-right-aside {
    flex: 0 0 100%;
    max-width: calc(100% - 15px);
    overflow: hidden;
  }
  .call-chat-body {
    .chat-box {
      overflow: auto;
      height: auto;
    }
  }
  // Email app
  .email-wrap {
    .email-right-aside {
      .email-body {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        .b-r-light {
          border-right: none !important;
        }
      }
      .radius-left {
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
      }
    }
    .row {
      .col-xl-3 {
        & + .col-xl-3 {
          padding-right: 15px;
        }
      }
      .col-xl-6 {
        padding-left: 15px;
      }
    }
  }
  // icons
  .icon-lists {
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 209px;
    }
  }
  // tour page
  .like-comment-lg-mb {
    margin-bottom: 12px;
  }
  // ribbons page
  .ribbon-space-bottom {
    top: 9px;
  }
  // touchspin page
  .bootstrap-touchspin {
    .touchspin-vertical-tab {
      .input-group {
        width: 98.5% !important;
      }
    }
  }
  // user-profile page
  .profile-img-style {
    #aniimated-thumbnials-3 {
      text-align: center;
    }
  }
  // vertical page
  .vertical-menu-mt {
    margin-top: 80px !important;
    .xl-none {
      display: block;
    }
  }
  .sm {
    position: fixed;
    background-color: $white;
    width: 300px;
    border-radius: 0;
    border: 1px solid $light-color;
    top: 80px;
    height: 100vh;
    left: -300px;
    z-index: 99;
    transition: all 0.3s ease;
    overflow: scroll;
  }
}
/* ========= responsive scss for 992 screen ========= */
@media only screen and (max-width: 991px) {
  .bottom-color-space {
    margin-bottom: -20px;
  }
  .search-form {
    .form-group {
      &:before {
        top: 31px;
      }
      &:after {
        top: 33px;
      }
    }
  }
  .customizer-contain {
    top: 62px;
  }
  .mobile-clock-widget {
    #date {
      margin-top: 20px;
    }
  }
  .m-r-30 {
    margin-right: 20px;
  }
  .card.full-card {
    top: 60px;
    width: calc(100vw - 0px);
  }
  .vertical-mobile-sidebar {
    right: 75px;
    top: 18px;
  }
  .gallery {
    margin-bottom: -20px;
  }
  .my-gallery {
    margin-bottom: -20px;
    figure {
      margin-bottom: 20px;
    }
  }
  .photoswipe-pb-responsive {
    .grid {
      margin-bottom: -20px;
      .grid-item {
        > div,
        li {
          margin-bottom: 20px;
        }
      }
    }
  }
  // timeline page
  .vertical-timeline--one-column {
    .vertical-timeline-element-icon {
      width: 40px;
      height: 40px;
    }
    .vertical-timeline-element-content {
      margin-left: 60px;
    }
    &:before {
      left: 18px !important;
    }
  }
  .vertical-timeline-element-icon {
    svg {
      width: 20px;
      height: 20px;
      left: 57%;
      top: 55%;
    }
  }
  // dropzone
  .dzu-previewContainer {
    padding: 20px !important;
  }
  // main header css
  .current_lang {
    .lang {
      span {
        &.lang-txt {
          display: none;
        }
      }
    }
  }
  .page-wrapper {
    .section-space {
      padding: 60px 0;
    }
    .card {
      margin-bottom: 30px;
      .card-header,
      .card-body,
      .card-footer {
        padding: 20px;
        .tab-content {
          .m-t-30 {
            margin-top: 20px !important;
          }
          .m-b-30 {
            margin-bottom: 20px !important;
          }
        }
      }
    }
    .page-body-wrapper {
      .page-header {
        padding-top: 25px;
        padding-bottom: 25px;
      }
      .footer-fix {
        margin-left: 0px;
        padding-right: 15px;
        width: calc(100% - 0px) !important;
      }
    }
    &.compact-wrapper {
      .left-menu-header {
        min-width: 0;
        .mega-menu {
          .nav-link {
            min-width: 130px;
          }
        }
      }
      .page-main-header {
        margin-left: 0 !important;
        width: calc(100% - 0px) !important;
        .main-header-right {
          .maximize {
            display: none;
          }
          .toggle-sidebar {
            position: unset;
            margin-right: 15px;
            border-right: 1px solid #ddd;
            padding-right: 15px;
            display: block;
            &:before {
              display: none;
            }
          }
        }
      }
      .page-body-wrapper {
        &.sidebar-icon {
          header {
            &.main-nav {
              .logo-wrapper {
                padding: 17px 30px;
                .back-btn {
                  display: inline-block;
                  float: right;
                  font-size: 20px;
                }
                .toggle-sidebar {
                  display: none;
                }
              }
              .main-navbar {
                .nav-menu {
                  height: calc(100vh - 95px);
                }
              }
              &.close_icon {
                transform: translate(-285px);
                &:hover {
                  transform: translate(-285px);
                }
                .main-navbar {
                  .nav-menu {
                    height: unset;
                  }
                }
              }
            }
          }
          .page-body {
            margin-top: 61px;
            padding: 0 15px;
          }
        }
      }
    }
    .page-main-header {
      .main-header-right {
        margin: 0;
        padding: 12px 30px;
        .search-full {
          input {
            line-height: 50px;
          }
          .form-group {
            &:before {
              top: 18px;
            }
            .close-search {
              top: 20px;
            }
          }
        }
        .nav-right {
          .notification {
            top: 10px;
          }
        }
        > .mobile-toggle {
          cursor: pointer;
          display: flex;
          align-items: center;
        }
        .main-header-left {
          margin-right: 0;
        }
      }
      .main-header-left {
        .logo-wrapper {
          img {
            margin-top: 0;
          }
        }
      }
    }
  }
  .page-body-wrapper {
    .page-body,
    footer {
      margin-left: 0 !important;
      #customer-review {
        .owl-stage-outer {
          width: 100% !important;
        }
      }
      .calender-widget {
        .cal-desc {
          p {
            max-height: 101px;
          }
        }
      }
    }
  }
  .default-according {
    .card {
      margin-bottom: 0 !important;
      .card-header {
        padding: 12px 20px;
      }
    }
  }
  // general widget page
  .crm-activity {
    > {
      li {
        + li {
          margin-top: 10px;
          padding-top: 10px;
        }
      }
    }
  }
  // add post page
  .add-post {
    form {
      .form-group {
        margin-bottom: 20px;
      }
    }
    .dropzone {
      margin-bottom: 20px;
    }
  }
  // avatar page
  .customers {
    &.avatar-group {
      margin-right: 20px;
    }
  }
  //social app page css
  .socialprofile {
    .social-btngroup {
      margin: 20px 0;
    }
    .social-group {
      margin-top: 20px;
    }
  }
  .social-status {
    form {
      .form-group {
        .form-control-social {
          margin-bottom: 20px;
        }
      }
    }
    .media {
      margin-bottom: 20px;
    }
  }
  .timeline-content {
    p,
    .comments-box {
      margin-top: 20px;
    }
  }
  .social-chat {
    margin-top: 20px;
    .media-body {
      padding: 20px;
    }
    .your-msg,
    .other-msg {
      margin-bottom: 20px;
    }
  }
  .social-network {
    span {
      margin-bottom: 20px;
    }
  }
  .details-about {
    + .details-about {
      margin-top: 20px;
    }
  }
  .activity-log {
    .my-activity {
      + .my-activity {
        margin-top: 20px;
      }
    }
  }
  .new-users-social {
    margin-bottom: 20px;
  }
  // job search
  .job-accordion,
  .faq-accordion {
    .card {
      margin-bottom: 20px !important;
    }
  }
  // timeline small page css
  .timeline-small {
    .media {
      margin-bottom: 20px;
      .media-body {
        h6 {
          margin-bottom: 20px;
        }
        p {
          width: 75%;
        }
      }
      .timeline-round {
        width: 45px;
        height: 45px;
        top: -14px;
        &.timeline-line-1 {
          &:after {
            bottom: -41px;
            height: 25px;
          }
        }
        &.small-line {
          &:after {
            bottom: -26px;
            height: 14px;
          }
        }
        &.medium-line {
          &:after {
            bottom: -33px;
            height: 17px;
          }
        }
        svg {
          top: 14px;
        }
      }
    }
  }
  // helper classes page start
  .helper-classes {
    padding: 20px;
  }
  // helper classes page ends
  .floated-customizer-btn,
  .floated-customizer-panel {
    display: none;
  }
  .weather-widget-two {
    .bottom-whetherinfo {
      .whether-content {
        top: 24px;
      }
    }
  }
  .social-widget-card {
    .b-b-light {
      padding: 20px;
      margin-bottom: 20px;
    }
  }
  .ecommerce-widget {
    .progress-showcase {
      margin-top: 20px;
    }
  }
  .crm-overall {
    margin: 0 -20px -20px;
  }
  .product-page-main {
    padding: 20px;
  }
  .grid-options {
    ul {
      li {
        &:nth-child(3) {
          display: none;
        }
      }
    }
  }
  .feature-products {
    form {
      .form-group {
        i {
          right: 20px;
        }
      }
    }
  }
  .tabbed-card ul {
    padding: 18px 15px;
  }

  .tabbed-card {
    ul {
      top: -7px;
      right: 0;
      &.border-tab {
        &.nav-tabs {
          padding: 0;
          .nav-item {
            .nav-link {
              padding: 10px 15px;
            }
          }
        }
      }
    }
  }
  .tilt-showcase {
    .tilt-image {
      text-align: center;
    }
    .mt-4 {
      margin-top: 20px !important;
    }
  }
  .todo {
    .todo-list-wrapper {
      .mark-all-tasks {
        top: 20px !important;
        right: 20px !important;
      }
    }
  }
  .custom-card {
    padding-bottom: 0;
    .card-footer {
      padding: 0 15px 15px 15px !important;
    }
    .card-header {
      border: none;
    }
    .dashboard-card {
      padding: 0;
    }
    .card-profile {
      img {
        height: 115px;
      }
    }
  }
  .display-1 {
    font-size: 4rem;
  }
  .display-2 {
    font-size: 3.5rem;
  }
  .display-3 {
    font-size: 2.5rem;
  }
  .display-4 {
    font-size: 1.5rem;
  }

  .typography {
    .h1 {
      font-size: 34px;
    }
    .h2 {
      font-size: 30px;
    }
    .h3 {
      font-size: 26px;
    }
    .h4 {
      font-size: 22px;
    }
    .h5 {
      font-size: 18px;
    }
    .h6 {
      font-size: 15px;
    }
  }
  h1 {
    font-size: 34px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 26px;
  }
  h4 {
    font-size: 22px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 15px;
  }

  .flot-chart-container {
    height: 300px;
  }
  .card-absolute {
    .card-header {
      padding: 10px 15px !important;
    }
  }

  .comingsoon {
    .comingsoon-inner {
      .countdown {
        .time {
          width: 80px;
          height: 80px;
          font-size: 26px;
        }
      }
    }
  }
  .owl-theme {
    .owl-nav {
      &.disabled {
        & + .owl-dots {
          margin-bottom: -10px;
          margin-top: 15px;
        }
      }
    }
  }
  .card {
    .card-header {
      h5 {
        font-size: 18px;
      }
      .card-header-right {
        top: 11px;
        right: 15px;
      }
    }
  }
  //user profile
  .user-profile {
    .hovercard {
      .info {
        .user-designation {
          margin-bottom: 20px;
        }
      }
    }
  }
  //blog
  .blog-single {
    .comment-box {
      .comment-social {
        li {
          padding-left: 15px;
        }
      }
    }
  }

  //error and maintenace
  .error-wrapper {
    padding: 35px 0;
    .sub-content {
      font-size: 14px;
      line-height: 25px;
      margin-top: 0px;
    }
    .error-heading {
      margin-top: 30px;
      .headline {
        font-size: 180px;
        margin-top: 0;
      }
    }
    .maintenance-heading {
      margin-top: 30px;
    }
  }

  //search
  .search-page {
    .info-block {
      flex-wrap: wrap;
    }
    .tab-content {
      .pb-4 {
        padding-bottom: 20px !important;
      }
    }
  }

  //coming soon
  .comingsoon {
    .comingsoon-inner {
      img {
        width: 150px;
      }
    }
  }
  //business dashboard
  .calender-widget {
    .cal-desc {
      padding: 10px 20px 20px !important;
      p {
        max-height: 100px;
      }
    }
  }
  .contact-form {
    padding-top: 10px !important;
    .theme-form {
      padding: 20px;
      margin-top: 27px;
      .form-icon {
        margin-top: -47px;
      }
    }
  }
  //crm dashboard
  .user-status {
    &.product-chart {
      max-height: 518px;
      overflow: auto;
    }
  }
  // general widget
  .widget-joins {
    .media {
      .media-body {
        i {
          float: none !important;
        }
      }
    }
  }
  .xl-none {
    display: block;
  }
  //blog
  .top-radius-blog {
    width: 100%;
  }
  .sm-100-w {
    width: 100%;
    height: 100%;
  }
  // tab material
  .border-tab.nav-tabs {
    margin-bottom: 20px;
  }
  .tab-content {
    ~ .nav-tabs {
      &.border-tab {
        margin-top: 20px;
      }
    }
  }
  // search page
  .search-page {
    .info-block {
      + .info-block {
        padding-top: 20px;
        margin-top: 20px;
      }
    }
    #video-links {
      .embed-responsive + .embed-responsive {
        margin-top: 20px;
      }
    }
  }
  .lg-mt {
    margin-top: 20px;
  }
  // clipboard
  .clipboaard-container {
    p {
      margin-bottom: 8px;
    }
  }
  // summer-note
  .m-b-30 {
    margin-bottom: 20px !important;
  }
  // email app
  .email-wrap {
    .email-left-aside {
      .email-app-sidebar {
        ul {
          li {
            hr {
              margin: 13px 0;
            }
          }
        }
      }
    }
  }
  // navs page
  .nav-md-mt {
    margin-top: 20px;
  }
  .navs-icon {
    padding: 20px;
    .main-section {
      padding-top: 20px;
    }
    .separator {
      margin: 20px 0;
    }
  }
  .nav-list {
    padding: 20px;
  }
  .navs-dropdown {
    button {
      margin-top: 20px;
    }
    .onhover-show-div {
      top: 68px;
    }
  }
  // animated modal page
  .animated-modal {
    .form-group {
      width: 100%;
      display: block;
    }
    .mr-4 {
      margin-right: 0 !important;
    }
    .animated-modal-md-mb {
      // margin-bottom: 20px!important;
    }
  }
  // steps page
  .steps-md-mt {
    margin-top: 20px;
  }
  // button group page
  .btn-group-wrapper {
    .m-b-30 {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
  // call-chat page
  .chat-box {
    .chat-left-aside {
      .people-list {
        height: 483px;
      }
    }
  }
}
/* ========= responsive scss for 768 screen ========= */
@media only screen and (max-width: 767px) {
  .page-wrapper {
    .nav-right {
      .nav-menus {
        margin-right: 60px;
      }
    }
    &.compact-wrapper {
      .left-menu-header {
        position: unset;
        .mega-menu {
          position: absolute !important;
          right: 30px;
          top: 13px;
          span {
            display: none;
          }
          .nav-link {
            min-width: auto;
            background-color: transparent;
            border-left: 1px solid #ddd;
            padding-right: 0;
            border-radius: 0;
          }
          svg {
            margin-right: 0;
            height: 20px;
            stroke: $theme-body-font-color;
          }
        }
      }
    }
  }
  .profile-media {
    .media-body {
      display: none;
    }
  }
  .form-inline {
    .form-group {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
  .credit-card {
    .text-center {
      img {
        margin-bottom: 20px;
      }
    }
  }
  .navbar-toggler {
    position: absolute;
    right: 15px;
    top: 10px;
  }
  .blog-box {
    .blog-details-main {
      .blog-social {
        li {
          padding: 0 12px;
        }
      }
    }
    .blog-details {
      .blog-social {
        li {
          &:first-child {
            padding-right: 25px;
          }
          + li {
            padding-left: 25px;
          }
        }
      }
    }
  }
  .footer-copyright {
    ~ div {
      text-align: center;
      p {
        float: none;
        margin-top: 5px;
      }
    }
  }
  // timepicker
  .time_picker_wrapper2 {
    display: block;
  }
  // social app page css
  .user-profile {
    .hovercard {
      .user-image {
        .share-icons {
          right: 25px;
          top: -19px;
          li {
            .social-icon {
              width: 35px;
              height: 35px;
            }
          }
        }
      }
    }
  }
  .tabs-scoial {
    &.border-tab {
      &.nav-tabs {
        display: flex;
      }
    }
  }
  //calendar
  #external-events {
    position: relative;
    margin-bottom: 20px;
  }
  .rbc-toolbar {
    display: block !important;
    margin: 0 auto;
    text-align: center;
    .rbc-btn-group {
      display: block;
      white-space: unset;
      &:first-child {
        margin-bottom: 10px;
      }
      &:last-child {
        margin-top: 10px;
      }
    }
  }
  .fc-toolbar {
    display: block !important;
    text-align: center;
    .fc-center {
      padding: 10px 0;
    }
  }
  // image cropper
  .input-cropper {
    width: 380px;
  }
  // timeline small page css
  .timeline-small {
    .media {
      .media-body {
        p {
          width: 100%;
        }
      }
    }
  }
  // icon-compact layout page
  .my-gallery {
    figure {
      &:nth-child(9),
      &:nth-child(10) {
        margin-bottom: 20px;
      }
    }
  }
  // chat
  .call-chat-sidebar {
    max-width: 100%;
    width: 100%;
    flex-basis: auto;
    .people-list {
      height: auto;
    }
  }
  .chat-left-aside {
    .people-list {
      ul {
        max-height: 100px;
      }
    }
  }
  .chat-box {
    .chat-history {
      .call-content {
        > div {
          padding-top: 60px;
        }
      }
    }
    .chat-left-aside {
      .people-list {
        height: auto;
      }
    }
    .chat-right-aside {
      .chat {
        .chat-message {
          .text-box {
            .btn {
              font-size: 0;
              &:before {
                position: absolute;
                content: "\e661";
                font-family: $font-themify;
                font-size: 18px;
                top: 11px;
                left: 20px;
              }
            }
          }
        }
      }
    }
    .border-tab {
      &.nav-tabs {
        display: flex;
      }
    }
    .chat-menu {
      .nav-tabs {
        .nav-item {
          a {
            height: 50px;
          }
        }
      }
    }
  }
  .clockpicker-align-top {
    left: 55px !important;
    top: 470px !important;
    .arrow {
      display: none;
    }
  }
  .reader {
    margin-top: 15px;
    height: auto !important;
  }
  .range-slider {
    &.theme-form {
      .form-group {
        margin-bottom: 0;
      }
    }
  }
  .note-editor {
    .btn-group {
      .btn {
        font-size: 11px;
      }
    }
  }
  .user-status {
    tbody {
      tr {
        td {
          min-width: 284px;
          & + td {
            min-width: unset;
            & + td {
              min-width: 100px;
            }
          }
        }
      }
    }
  }
  .text-md-right {
    text-align: right;
  }
  .star-ratings {
    .stars {
      padding: 0;
    }
  }
  .form-inline {
    &.d-inline-block {
      display: block !important;
      width: 100%;
      .form-control {
        margin-bottom: 10px;
      }
    }
  }
  .reset-password-box {
    width: 500px;
    margin: 0 auto;
    .card {
      padding: 20px;
    }
  }
  .custom-card {
    .card-profile {
      img {
        height: 136px;
        bottom: 0;
      }
    }
  }
  .error-wrapper {
    .maintenance-icons {
      li {
        i {
          color: $light-color;
          font-size: 30px;
        }
        &:nth-child(2) {
          i {
            font-size: 60px;
            margin-top: -10px;
            margin-left: 70px;
          }
        }
        &:nth-child(3) {
          i {
            font-size: 90px;
            margin-right: 80px;
          }
        }
      }
    }
  }
  .authentication-main {
    .auth-innerright {
      .social-media {
        li {
          font-size: 14px;
        }
      }
      min-height: unset;
      padding: 0;
    }
  }
  .loader-box {
    justify-content: center;
  }
  .btn-group-wrapper {
    text-align: center;
    .m-b-30 {
      margin-bottom: 15px !important;
    }
  }

  .btn-group-showcase {
    text-align: center;
    .btn-group {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
  .btn-group {
    .btn {
      font-size: 14px;
      padding: 0.375rem 1.3rem;
    }
  }
  .animate-widget {
    .text-center {
      display: none;
    }
  }
  //general widget page
  .calender-widget {
    .cal-desc {
      p {
        max-height: 100%;
      }
    }
  }
  .user-status {
    &.product-chart {
      max-height: 100%;
    }
    table {
      thead {
        tr {
          th {
            &:nth-child(3) {
              min-width: 124px;
            }
          }
        }
      }
    }
  }
  /* font-awesom icon page */
  .icon-hover-bottom {
    .form-group {
      input {
        min-width: 270px;
      }
    }
  }

  //user profile
  .user-profile {
    .hovercard {
      .cardheader {
        height: 350px;
      }
      .info {
        padding: 35px;
        .ttl-info {
          margin-bottom: 20px;
        }
        .ttl-sm-mb-0 {
          margin-bottom: 0;
        }
      }
    }
  }
  //blog
  .blog-single {
    .comment-box {
      .media {
        h6 {
          margin-bottom: 10px;
        }
        img {
          margin-right: 30px;
        }
      }
      .comment-social {
        margin-bottom: 5px;
        li:first-child {
          padding-left: 0;
          padding-right: 15px;
        }
      }
    }
  }

  //material tab
  .tabbed-card {
    ul {
      &.border-tab {
        margin-bottom: 15px;
        &.nav-tabs {
          top: unset;
          .nav-item {
            .nav-link {
              padding: 5px 15px 10px;
            }
          }
        }
      }
      padding: 10px 0 5px 0;
      position: relative;
      width: 100%;
      left: 0;
    }
  }

  //search
  .search-page {
    #image-links {
      .info-block {
        &.m-t-30 {
          margin-top: 0 !important;
        }
      }
    }
  }

  //authentication
  .authentication-main {
    .authentication-box {
      width: 100%;
    }
    .auth-innerright {
      display: inherit;
    }
  }
  //chart widget
  .bar-chart-widget {
    .earning-details {
      i {
        right: 0;
      }
    }
  }
  .bottom-content {
    padding: 10px;
    p {
      font-size: 12px;
    }
  }
  // icons
  .icon-hover-bottom {
    .icon-popup {
      display: block;
      > .icon-last {
        display: block;
        margin-top: 15px;
      }
      div {
        .flag-icon {
          width: 30px;
          height: auto;
        }
      }
    }
    .form-group {
      input {
        display: block;
        width: unset;
      }
    }
  }

  //footer
  .footer-links {
    text-align: center;
  }
  .footer-copyright {
    text-align: center !important;
  }
  .jvector-map-height {
    height: 150px;
  }
  .sm-left-text {
    text-align: left !important;
  }
  // email app
  .email-wrap {
    .email-right-aside {
      .email-content {
        .email-top {
          .d-flex {
            padding-bottom: 0;
          }
          .float-right {
            float: left !important;
          }
        }
        .email-wrapper {
          .attachment {
            text-align: center;
            ul {
              li {
                &:last-child {
                  padding-right: 0;
                }
                img {
                  width: 140px;
                  height: 140px;
                }
              }
            }
          }
        }
      }
    }
  }
  // alert page
  .alert-dismissible {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 294px;
    }
  }
  // button group page
  .button-group-mb-sm {
    margin-bottom: 5px;
  }
  // table components page
  .card-block {
    .table-responsive {
      .table {
        tbody {
          tr {
            td {
              span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100px;
              }
            }
          }
        }
      }
      .progress-content {
        tbody {
          tr {
            td {
              span {
                text-overflow: ellipsis;
                white-space: normal;
                max-width: 50px;
              }
            }
          }
        }
      }
      .checkbox-td-width,
      .radio-first-col-width {
        tbody {
          tr {
            td {
              min-width: 200px !important;
            }
          }
        }
      }
    }
  }
  // chat page
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-msg-box {
          height: auto;
        }
      }
    }
    .chat-menu {
      .nav-tabs {
        .nav-item {
          width: 32.33%;
        }
      }
      .people-list {
        ul.list {
          max-height: 433px;
          margin-bottom: 15px;
        }
      }
      #info-profile,
      #info-contact {
        margin-bottom: 15px;
      }
    }
  }
  // user-profile page
  .hovercard {
    .info {
      .follow {
        .text-md-right {
          text-align: center;
        }
      }
    }
  }
  .profile-img-style {
    .like-comment-sm-mb {
      margin-bottom: 20px;
    }
  }
  // forget-password page
  .reset-password-box {
    .theme-form {
      .form-group {
        .btn {
          margin-top: 15px !important;
        }
      }
    }
  }
  // gallery page
  .lg-outer {
    .lg-actions .lg-next,
    .lg-actions .lg-prev {
      top: 48%;
    }
  }
  // product page
  .product-sidebar {
    &.open {
      top: -37px;
    }
  }
  .feature-products {
    .text-right {
      text-align: left !important;
    }
  }
  .grid-options {
    ul {
      li {
        display: none;
      }
    }
  }
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        .product-img {
          width: 42%;
        }
      }
    }
  }
  .product-filter {
    .product-box {
      flex-wrap: nowrap;
      .product-img {
        width: 100px;
      }
      .product-details {
        padding: 0 !important;
      }
    }
  }
}
/* ========= responsive scss for 575 screen ========= */
@media only screen and (max-width: 575px) {
  .job-accordion {
    .card {
      box-shadow: 0 0 11px 5px $common-shadow-color !important;
    }
  }
  .alert-dismissible {
    p {
      max-width: 209px;
    }
  }
  .m-r-30 {
    margin-right: 15px;
  }
  .tab-javascript {
    .nav-pills {
      text-align: left;
    }
    margin-bottom: 15px;
  }
  .reset-password-box {
    .theme-form {
      .opt-box {
        .opt-text {
          font-size: 26px;
        }
      }
    }
  }
  // main header css
  .translate_wrapper {
    &.active {
      .more_lang {
        &.active {
          position: fixed;
          width: calc(100vw - 30px);
          left: 15px;
          top: 60px;
        }
      }
    }
  }
  .page-main-header {
    .main-header-right {
      .nav-right {
        &.right-menu {
          ul {
            &.profile-dropdown,
            &.chat-dropdown,
            &.notification-dropdown {
              width: calc(100vw - 30px);
              position: fixed;
              top: 60px;
              left: 15px !important;
            }
          }
        }
      }
    }
  }
  .page-wrapper {
    .nav-right {
      .nav-menus {
        margin-right: 45px;
      }
    }
    .main-header-right {
      .nav-right {
        ul {
          li {
            svg {
              width: 16px;
            }
          }
        }
      }
    }
    .page-main-header {
      .main-header-right {
        padding: 12px 15px !important;
        .nav-right {
          > ul {
            top: 58px;
            .search-form {
              i {
                display: none;
              }
            }
          }
          &.right-menu {
            ul {
              // justify-content: center;
              justify-content: flex-end;
              li {
                &.px-0 {
                  padding: 6px !important;
                }
              }
            }
          }
          > ul {
            li {
              margin-right: 1px;
              .profile-media {
                img {
                  width: 25px;
                  height: 25px;
                }
              }
              &.onhover-dropdown {
                ul {
                  li {
                    margin-right: 0 !important;
                  }
                }
              }
            }
          }
          .notification-dropdown {
            left: -16px !important;
            &.onhover-show-div {
              &:before,
              &:after {
                left: 24px !important;
                right: unset !important;
              }
            }
          }
          .chat-dropdown {
            left: -52px;
            width: 300px;
            &:after,
            &:before {
              left: 58px !important;
            }
          }
        }
      }
    }
    .page-body-wrapper {
      .page-header {
        > .row {
          .col-6 {
            &:first-child {
              display: block;
            }
          }
        }
        .btn-group {
          .btn {
            padding: 8px 30px;
          }
        }
      }
    }
  }
  .bookmark {
    ul {
      li {
        a {
          svg {
            width: 16px;
            height: 16px;
          }
        }
        + li {
          margin-left: 8px;
        }
      }
    }
  }
  // main sidebar css
  .page-wrapper {
    .nav-right {
      .nav-menus {
        margin-right: 45px;
      }
    }
    &.compact-wrapper {
      .left-menu-header {
        .mega-menu {
          top: 17px;
          right: 15px;
          .nav-link {
            padding-left: 10px;
            padding-top: 3px;
            padding-bottom: 3px;
          }
        }
      }
      .page-main-header {
        .main-header-right {
          .toggle-sidebar {
            margin-left: 0;
            padding-left: 0;
            padding-right: 10px;
            svg {
              width: 18px;
            }
          }
          .logo-wrapper {
            display: none;
          }
        }
      }
      .page-body-wrapper {
        &.sidebar-icon {
          .page-body {
            margin-top: 60px;
          }
          header {
            &.main-nav {
              .main-navbar {
                .nav-menu {
                  height: calc(100vh - 79px);
                }
              }
            }
          }
        }
      }
    }
  }
  // general widget page css
  .mobile-clock-widget {
    #date {
      margin-top: 15px;
    }
  }
  .social-widget-card {
    .b-b-light {
      padding: 15px;
      margin-bottom: 15px;
    }
  }
  .weather-widget-two {
    .bottom-whetherinfo {
      .whether-content {
        top: 34px;
      }
    }
  }
  .cal-date-widget {
    .cal-datepicker {
      margin-top: 15px;
      text-align: center;
    }
  }
  .static-top-widget {
    .media-body {
      .icon-bg {
        width: 70px;
        height: 70px;
      }
    }
  }
  // sticky
  .note {
    min-width: 240px !important;
    min-height: 240px !important;
  }
  // Add post page css
  .add-post {
    form {
      .m-checkbox-inline {
        label {
          margin-right: 15px;
        }
      }
      .form-group {
        margin-bottom: 15px;
      }
    }
    .dropzone {
      margin-bottom: 15px;
    }
  }
  // dropzone
  .dzu-previewContainer {
    padding: 15px !important;
  }
  // upload page
  .fileContainer {
    .uploadPictureContainer {
      width: 100% !important;
      margin: 3% !important;
    }
    .deleteImage {
      font-size: 13px !important;
      line-height: 20px !important;
      width: 20px !important;
      height: 20px !important;
    }
  }
  // rating
  .rating {
    .text-right {
      text-align: left !important;
      margin-top: 5px;
    }
  }
  // avatar page css
  .customers {
    &.avatar-group {
      margin-right: 15px;
    }
  }
  .vertical-mobile-sidebar {
    top: 19px;
  }
  //notify index page
  .alert-theme {
    i {
      margin-right: 10px !important;
    }
    button {
      top: 2px !important;
    }
  }
  .bar-chart-widget {
    .earning-details {
      i {
        right: -30px;
      }
    }
  }
  .widget-joins {
    &:before {
      display: none;
    }
    .pl-0 {
      padding-left: 15px !important;
    }
    .border-after-xs {
      &:after {
        content: "";
        position: absolute;
        height: 1px;
        width: 94%;
        background-color: $light-semi-gray;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }
  .clockpicker-align-top {
    left: 45px !important;
    top: 406px !important;
  }
  .gallery {
    margin-bottom: -15px;
  }
  .my-gallery {
    margin-bottom: -15px;
    figure {
      margin-bottom: 15px;
    }
  }
  .photoswipe-pb-responsive {
    .grid {
      margin-bottom: -15px;
      .grid-item {
        > div,
        li {
          margin-bottom: 15px;
        }
      }
    }
  }
  // social app page css
  .avatar-showcase {
    .pepole-knows {
      text-align: center;
      ul {
        li {
          margin-right: 14px;
        }
      }
    }
  }
  .social-app-profile {
    .hovercard {
      .user-image {
        .avatar {
          margin-top: -56px;
          img {
            width: 100px;
            height: 100px;
          }
        }
        .icon-wrapper {
          left: 52%;
          top: 10px;
          height: 30px;
          width: 30px;
          font-size: 15px;
          &:hover {
            font-size: 12px;
          }
        }
        .share-icons {
          right: 15px;
          li {
            margin-right: 3px;
          }
        }
      }
      .info {
        .tabs-scoial {
          .user-designation {
            border-top: none;
            border-bottom: none;
            margin-bottom: 0;
            padding: 0px 0px;
          }
        }
      }
    }
    .tabs-scoial {
      margin-bottom: 0 !important;
    }
  }
  .market-tabs {
    .nav {
      .nav-item {
        .nav-link {
          height: 45px;
        }
      }
    }
  }
  .socialprofile {
    .social-btngroup {
      margin: 15px 0;
    }
    .social-group {
      margin-top: 15px;
    }
  }
  .social-status {
    form {
      .form-group {
        .form-control-social {
          margin-bottom: 15px;
        }
      }
    }
    .media {
      margin-bottom: 15px;
    }
  }
  .new-users-social {
    margin-bottom: 15px;
  }
  .tabs-scoial {
    position: relative;
    padding-top: 80px;
    li {
      &:nth-child(3) {
        position: absolute;
        top: 25px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    &.border-tab {
      &.nav-tabs {
        .nav-item {
          .nav-link {
            padding: 10px 18px;
          }
        }
      }
    }
  }
  .timeline-content {
    p,
    .comments-box {
      margin-top: 15px;
    }
    .comments-box {
      .media {
        .m-r-20 {
          margin-right: 10px;
        }
        .media-body {
          .input-group {
            .input-group-append {
              .btn {
                padding: 6px;
              }
            }
          }
        }
      }
    }
    .comment-number {
      i {
        margin-right: 15px;
      }
    }
  }
  #cd-timeline {
    margin-right: 0;
  }
  .social-chat {
    margin-top: 15px;
    .media-body {
      padding: 15px;
    }
    .your-msg,
    .other-msg {
      margin-bottom: 15px;
    }
  }
  .social-network {
    span {
      margin-bottom: 15px;
    }
  }
  .social-list {
    .media {
      margin-bottom: 15px;
    }
  }
  .details-about + .details-about {
    margin-top: 15px;
  }
  .your-details-xs {
    margin-top: 15px;
  }
  .social-header {
    h5 {
      span.pull-right {
        float: right !important;
        margin-top: 0 !important;
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .activity-log {
    .my-activity {
      + .my-activity {
        margin-top: 15px;
      }
    }
  }
  .photos {
    ul {
      li {
        width: 26%;
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
  // contact
  .contact-filter {
    h5 {
      margin-top: 0;
    }
    .input-range {
      margin-top: -8px;
    }
  }
  .contact-table {
    table {
      th,
      td {
        padding: 15px;
      }
    }
  }
  // search page
  .search-page {
    .search-form {
      .form-group {
        &:before {
          left: 75px;
        }
        &:after {
          left: 45px;
        }
      }
    }
  }
  // apex chart
  #donutchart,
  #piechart {
    .apexcharts-legend {
      display: none;
    }
  }
  // datatable
  .rdt_TableHeader {
    > div {
      &:last-child {
        > div {
          font-size: 14px;
        }
      }
    }
  }
  // timeline small page css
  .timeline-small {
    .media {
      .media-body {
        p {
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
        }
      }
      .small-line {
        ~ .media-body {
          p {
            -webkit-line-clamp: 1;
          }
        }
      }
    }
  }

  // helper classes page start
  .helper-classes {
    padding: 15px;
  }
  // helper classes page ends
  .nav-tabs {
    .nav-item {
      &.show {
        .nav-link {
          border-top: 0;
          border-bottom: 0;
        }
      }
    }
    .nav-link {
      border-top: 0;
      border-bottom: 0;
      &:hover,
      &.active,
      &:focus {
        border-top: 0;
        border-bottom: 0;
      }
    }
  }

  .border-tab {
    &.nav-tabs {
      .nav-item {
        width: 100% !important;
      }
    }
    .weather-widget-two {
      .bottom-whetherinfo {
        .whether-content {
          top: 35px;
        }
      }
    }
    .social-widget-card {
      .b-b-light {
        padding: 15px;
        margin-bottom: 15px;
      }
    }
    .ecommerce-widget {
      .progress-showcase {
        margin-top: 15px;
      }
    }
  }
  .nav {
    display: inherit;
    text-align: center;
    border-bottom: none;
  }
  // tab material start
  .border-tab.nav-left {
    .nav-link {
      text-align: center;
      border-left: none;
    }
  }
  .border-tab.nav-right {
    .nav-link {
      text-align: center;
      border-right: none;
    }
  }
  .border-tab.nav-tabs {
    margin-bottom: 10px;
  }
  .tab-content {
    ~ .nav-tabs {
      &.border-tab {
        margin-top: 10px;
      }
    }
  }
  // tab material end
  .nav-pills {
    text-align: center;
  }
  .crm-overall {
    margin: 0 -15px -15px;
  }
  .product-page-main {
    padding: 15px;
    .m-t-15 {
      margin-top: 0 !important;
      .btn {
        margin-top: 10px;
      }
    }
  }
  .todo {
    .action-box {
      &.large {
        height: 25px !important;
        width: 25px !important;
        .icon {
          font-size: 14px !important;
          vertical-align: -3px !important;
        }
      }
    }
    .todo-list-wrapper {
      #todo-list {
        li {
          .task-container {
            .task-label {
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }
  .redial-chart-block {
    text-align: center;
  }
  #nav-tabContent {
    margin-top: 15px;
  }
  .reset-password-box {
    width: 430px;
  }
  .auth-bg {
    padding: 25px 15px;
  }
  .auth-bg-effect {
    display: none;
  }
  .date-picker {
    .text-right {
      text-align: left !important;
    }
  }
  ul {
    &.pagination {
      li {
        &:before {
          line-height: 3;
        }
      }
    }
  }
  .page-wrapper {
    .page-body-wrapper {
      .user-profile {
        .profile-img-style {
          padding: 15px;
          .img-container {
            margin-top: 15px;
          }
        }
        hr {
          margin: 15px 0;
        }
        .like-comment {
          margin-top: 15px;
        }
      }
    }
  }
  .jvector-map-height {
    height: 250px;
  }
  .user-profile {
    .order-sm-0 {
      order: -1;
    }
    .hovercard {
      .info {
        .ttl-info {
          text-align: center !important;
        }
        .ttl-xs-mt {
          margin-top: 20px;
        }
      }
    }
  }
  .icon-hover-bottom {
    .form-group {
      display: block;
      margin-bottom: 0;
      .btn {
        margin-top: 15px;
      }
    }
    .icon-popup {
      padding: 15px;
    }
    svg {
      &.climacon {
        height: 50px;
        width: 50px;
      }
    }
  }
  .blog-box {
    &.blog-shadow {
      &:before {
        box-shadow: inset 0px -100px 100px -13px rgba(0, 0, 0, 0.6);
      }
    }
    .blog-details {
      p,
      .single-blog-content-top {
        margin-top: 15px;
      }
      h4 {
        margin-top: 15px;
      }
      .blog-social {
        margin-top: 15px;
        li {
          & + li {
            padding-left: 20px;
            > span {
              display: none;
            }
          }
          &:first-child {
            margin-bottom: 10px;
            padding-right: 20px;
          }
        }
      }
    }
  }
  .comment-box {
    padding-top: 40px;
    padding-bottom: 40px;
    ul {
      ul {
        margin-left: 30px;
      }
    }
    li {
      .media {
        display: block;
        img {
          height: 50px;
          width: 50px;
          padding: 3px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .ace-editor {
    height: 320px;
  }
  .gallery {
    > a {
      margin-bottom: 15px;
      text-align: center;
    }
  }
  .card {
    .card-header {
      h5 {
        font-size: 17px;
      }
    }
  }
  .theme-form {
    .form-group {
      margin-bottom: 15px;
    }
  }
  .page-wrapper {
    .page-body-wrapper {
      .default-according {
        .card {
          .card-header,
          .card-body,
          .card-footer {
            padding: 0.75rem 1.25rem;
          }
        }
      }
      .card {
        margin-bottom: 20px;
        .card-header,
        .card-body,
        .card-footer {
          .tab-content {
            .m-t-30 {
              margin-top: 20px !important;
            }
            .m-b-30 {
              margin-bottom: 20px !important;
            }
          }
        }
      }
      .page-body {
        padding: 0 !important;
      }
      .page-header {
        padding-top: 20px;
        padding-bottom: 20px;
        .row {
          h3 {
            font-size: 20px;
          }
        }
      }
    }
  }
  /* alert responsive css start here */
  $alert-name: primary, secondary, success, danger, warning, info, light, dark;
  $alert-color: $primary-color, $secondary-color, $success-color, $danger-color, $warning-color, $info-color,
    $light-color, $dark-color;

  @each $var in $alert-name {
    $i: index($alert-name, $var);
    .alert-#{$var}.inverse {
      background-color: transparent;
    }
  }
  .alert {
    .close {
      height: 100%;
    }
  }
  .calender-widget {
    .cal-desc {
      padding: 10px 15px 15px !important;
      p {
        max-height: 100%;
      }
    }
  }

  //user profile
  .user-profile {
    hr {
      margin: 15px 0;
    }
    .hovercard {
      .cardheader {
        height: 300px;
      }
      .info {
        padding: 20px;
        .user-designation {
          border-top: 1px solid $light-color;
          border-bottom: 1px solid $light-color;
          margin-bottom: 15px;
          padding: 15px 0px;
        }
      }
      .social-media {
        a {
          margin-right: 0px;
          font-size: 16px;
        }
      }
      .follow {
        .follow-num {
          font-size: 20px;
        }
      }
    }
    .profile-img-style {
      .pictures {
        img {
          margin-bottom: 10px;
        }
      }
    }
  }

  //user card
  .custom-card {
    .card-footer {
      > div {
        h6 {
          font-size: 14px;
          font-weight: 600;
        }
        h3 {
          font-size: 24px;
        }
      }
    }
  }
  // megaoption page responsive scss
  .mega-inline {
    display: block;
  }
  .megaoptions-border-space-sm {
    padding-bottom: 30px !important;
  }
  //blog
  .blog-box.blog-shadow {
    .blog-details {
      padding: 15px;
      p {
        margin-bottom: 0;
      }
      h4 {
        margin-bottom: 10px;
      }
    }
  }
  .blog-box {
    &.blog-list {
      .blog-details {
        padding-top: 0;
      }
    }
    .blog-details {
      padding: 20px;
    }
    .blog-date {
      span {
        font-size: 25px;
      }
    }
  }
  .blog-single {
    .blog-box {
      .blog-details {
        padding: 0;
      }
    }
  }

  //error and maintenace
  .error-wrapper {
    padding: 20px 0;
    .btn {
      margin-top: 15px;
    }
    .img-100 {
      width: 50px !important;
    }
    .error-heading {
      margin-top: 20px;
      .cloud-second {
        margin-top: -60px;
      }
      .headline {
        font-size: 150px;
      }
    }
    .maintenance-heading {
      .cloud-second {
        display: none;
      }
      .headline {
        font-size: 25px;
        margin-top: -10%;
        letter-spacing: 2px;
      }
    }
  }

  //coming soon
  .comingsoon {
    .comingsoon-inner {
      .coming-soon-bottom-link {
        margin-top: 20px;
        line-height: 1;
      }
      h5 {
        margin-bottom: 20px;
        margin-top: 20px;
      }
      .countdown {
        padding: 20px 0px;
        ul li {
          margin: 0 7px;
        }
        .title {
          font-size: 12px;
        }
        .time {
          width: 45px;
          height: 45px;
          line-height: 45px;
          font-size: 16px;
          margin: 0 auto;
        }
      }
    }
  }
  //chart
  .flot-chart-container {
    height: 300px;
  }
  .status-widget {
    .text-sm-right {
      text-align: right;
    }
  }
  // navs page
  .nav-md-mt {
    margin-top: 15px;
  }
  .navs-icon {
    padding: 15px;
    .main-section {
      padding-top: 15px;
    }
    .separator {
      margin: 15px 0;
    }
  }
  .nav-list {
    padding: 15px;
  }
  .navs-dropdown {
    button {
      margin-top: 15px;
    }
    .onhover-show-div {
      top: 64px;
    }
  }
  .lg-mt {
    margin-top: 15px;
  }
  // tilt
  .tilt-showcase {
    .mt-4 {
      margin-top: 15px !important;
    }
    ol {
      .m-b-20 {
        margin-bottom: 15px !important;
      }
    }
  }

  // state color page
  .xs-mt {
    margin-top: 15px;
  }
  // tag-pills page
  span:last-child.tag-pills-sm-mb {
    margin-top: 3px;
  }
  // alert page

  // animated modal page
  .animated-modal {
    .form-group {
      .form-control {
        width: 50%;
        margin: 0 auto;
      }
    }
    .animated-modal-md-mb {
      margin-bottom: 15px !important;
    }
  }
  // steps page
  .steps-md-mt {
    margin-top: 15px;
  }
  .steps-sizing-sm-mb {
    margin-bottom: 15px;
  }
  // touchspin page
  .bootstrap-touchspin {
    .touchspin-vertical-tab {
      .input-group {
        width: 96% !important;
      }
    }
  }
  // chart-flot page
  .chart-block {
    .flot-chart-container {
      p#choices {
        width: 100%;
      }
      #toggling-series-flot {
        width: 100% !important;
      }
    }
  }
  // summer-note page
  .click2edit {
    & ~ .note-editor {
      &.note-frame {
        margin-bottom: 15px;
      }
    }
  }
  // support ticket page
  .ecommerce-widget {
    .progress-showcase {
      margin-top: 15px;
    }
  }
  // forget-password page
  .reset-password-box {
    .theme-form {
      .form-group {
        .btn {
          margin-top: 13px !important;
        }
      }
    }
  }
  // peity chart page
  .xm-mb-peity {
    margin-bottom: 8px;
  }
  // login page
  .authentication-main {
    padding: 30px 15px;
  }
}
/* ========= responsive scss for 480 screen ========= */
@media only screen and (max-width: 480px) {
  .footer-content {
    .btn-md {
      margin-bottom: 10px;
    }
  }
  .box-layout {
    &.page-wrapper {
      .set-col-6,
      .set-col-3,
      .set-col-7,
      .set-col-5 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  ul {
    &.notification-dropdown {
      &.onhover-show-div {
        width: 260px;
        right: -80px;
        &:before,
        &:after {
          right: 89px !important;
        }
      }
    }
  }
  .inline-block-sm {
    display: inline-block;
  }
  .pagination-lg {
    .page-link {
      padding: 0.3rem 0.65rem;
      font-size: 15px;
    }
  }
  .nav-pills {
    li {
      width: 100%;
    }
  }
  .reset-password-box {
    width: 290px;
  }
  .icon-hover-bottom {
    .icon-first {
      margin-right: 10px;
    }
    .icon-popup {
      .icon-class {
        display: none;
      }
    }
  }

  .error-wrapper {
    .maintenance-heading {
      margin-top: 0;
    }
  }
  .card {
    .card-header {
      > span {
        & + span {
          display: none;
        }
      }
    }
  }
  .custom-card {
    padding: 0;
    .card-footer {
      > div {
        h3 {
          font-size: 20px;
        }
      }
    }
    .card-social {
      li {
        padding: 5px 0;
      }
    }
    .profile-details {
      h6 {
        margin-bottom: 15px;
      }
      h4 {
        font-size: 20px;
      }
    }
    .card-profile {
      img {
        height: 93px;
      }
    }
  }

  .card {
    .card-header {
      .card-header-right {
        i {
          margin: 0 3px;
          font-size: 14px;
        }
      }
    }
  }
  // kanban board
  .jkanban-container {
    .kanban-container {
      .kanban-board {
        width: 100%;
      }
    }
  }
  // image cropper
  .input-cropper {
    width: auto;
  }
  // leaflet map css
  .leaflet-container {
    height: 240px;
  }
  //blog
  .blog-box.blog-shadow {
    .blog-details {
      padding: 10px;
      h4 {
        font-size: 14px;
      }
    }
  }
  .cal-date-widget {
    padding: 30px;
  }

  .calender-widget {
    .cal-img {
      height: 225px;
    }
  }
  // email app
  .email-wrap {
    .email-right-aside {
      .email-content {
        .email-wrapper {
          .attachment {
            ul {
              li {
                img {
                  width: 83px;
                  height: 83px;
                }
              }
            }
          }
        }
      }
    }
  }
  //  tag-pills
  .badge {
    padding: 0.42em 0.7em;
  }
  // alert page
  .alert-dismissible {
    .close {
      top: -1px;
      span {
        font-size: 19px;
      }
    }
  }
  // bootstrap notify page
  .notify-alert {
    width: 90%;
  }
  // invoice template page
  .invoice {
    .text-md-right {
      margin-top: 10px;
    }
  }
  // tab css
  .tabbed-card {
    ul {
      &.border-tab {
        &.nav-tabs {
          .nav-item {
            .nav-link {
              padding: 5px 15px 8px;
            }
          }
        }
      }
    }
  }
  .border-tab {
    &.nav-tabs {
      display: block;
    }
  }
  .search-page {
    .border-tab {
      .dropdown-toggle {
        margin: 10px auto 0;
      }
    }
  }
}
@media only screen and (max-width: 420px) {
  .customizer-contain {
    width: 235px;
    .customizer-header {
      .icon-close {
        right: 20px;
      }
    }
    .customizer-body {
      padding: 15px;
      .layout-grid {
        &.customizer-mix {
          .color-layout {
            width: 60px;
            height: 56px;
          }
        }
      }
      .main-layout,
      .sidebar-type,
      .sidebar-setting {
        > li {
          width: 60px;
          height: 56px;
          > .body {
            ul {
              .body {
                .badge {
                  left: -16px;
                }
              }
            }
          }
          &:nth-child(2) {
            > .body {
              ul {
                .body {
                  .badge {
                    right: 3px;
                  }
                }
              }
            }
          }
        }
      }
      .sidebar-type {
        &.layout-grid:not(.customizer-color) {
          li {
            > .body {
              height: calc(100% - 10px);
            }
          }
        }
      }
      .color-layout {
        width: 27px;
        height: 27px;
        padding: 2px;
      }
      .sidebar-setting {
        li {
          .body {
            .badge {
              top: 6px;
              position: absolute;
              left: 0;
              width: 50px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
      .layout-grid {
        .bg-layout,
        .img-layout {
          width: 30px;
          height: 30px;
        }
      }
      .tab-pane {
        > h6 {
          font-size: 16px;
        }
      }
    }
  }
  .customizer-color {
    margin-bottom: -13px;
  }
  .customizer-links {
    .nav-link {
      .settings {
        img {
          width: 56px;
        }
      }
    }
    &.open {
      right: 235px;
    }
  }
  // product page
  .product-sidebar {
    &.open {
      top: -64px;
    }
  }
}
@media only screen and (max-width: 397px) {
  .clockpicker-align-top {
    top: 426px !important;
  }
}

/* ========= responsive scss for 360 screen ========= */
@media only screen and (max-width: 360px) {
  .datepicker {
    width: 239px;
  }
  .add-post {
    form {
      .m-checkbox-inline {
        label {
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .alert-theme {
    font-size: 12px;
    button {
      right: 30px !important;
    }
    i {
      display: none;
    }
    span {
      + span {
        + span {
          padding: 15px;
        }
      }
    }
  }
  .bar-chart-widget {
    .earning-details {
      i {
        right: -73px;
      }
    }
  }
  .widget-joins {
    .border-after-xs {
      &:after {
        width: 92%;
      }
    }
  }
  // main header
  .page-wrapper {
    .page-main-header {
      .main-header-left {
        .logo-wrapper {
          img {
            margin-top: 5px;
          }
        }
      }
      .main-header-right {
        .nav-right {
          > ul {
            > li {
              padding: 6px 5px;
            }
          }
        }
      }
    }
  }
  // chart widget page css
  .status-widget {
    svg {
      width: 18px;
      height: 18px;
    }
  }
  // timepicker {
  .time_picker_wrapper {
    width: 250px;
  }
  .time_picker_modal_container {
    width: 278px !important;
  }
  .picker_container {
    margin: 0 10px 10px !important;
  }
  .time_picker_wrapper2 {
    .time_picker_container {
      width: 254px !important;
    }
  }
  // user cards page css
  .custom-card {
    .card-footer {
      > div {
        h6 {
          font-size: 12px;
        }
      }
    }
  }
  // social app page css
  .tabs-scoial {
    &.border-tab {
      &.nav-tabs {
        .nav-item {
          .nav-link {
            padding: 10px 10px;
            font-size: 14px;
          }
        }
      }
    }
  }
  .user-profile {
    .hovercard {
      .user-image {
        .share-icons {
          right: 6px;
          top: -15px;
          li {
            .social-icon {
              width: 25px;
              height: 25px;
              i {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  .social-chat {
    .other-msg {
      margin-left: 15px;
    }
  }
  .social-header {
    h5 {
      span.pull-right {
        display: none;
      }
    }
  }
  .custom-card {
    .dashboard-card {
      padding-top: 0;
    }
    .card-profile {
      img {
        bottom: 25px;
      }
    }
  }
  .flot-chart-container {
    height: 230px;
  }
  .blog-box {
    .blog-details {
      p {
        line-height: 1.5;
      }
    }
    .blog-details-main {
      .blog-social {
        li {
          padding: 0 10px;
        }
      }
    }
  }
  /* responsive material tabs*/
  .border-tab.nav-tabs {
    .nav-item {
      .nav-link {
        padding: 10px 0px;
      }
    }
  }
  .tabs-responsive-side {
    max-width: 100%;
  }
  //default dashboard
  .browser-widget {
    .media {
      i {
        font-size: 52px;
      }
      .media-body {
        h4 {
          font-size: 20px;
        }
      }
    }
  }
  .bar-chart-widget {
    .bottom-content {
      .num {
        font-size: 20px;
      }
    }
  }
  .user-status {
    table {
      tbody {
        img {
          height: 40px;
        }
      }
    }
  }
  // email app
  .email-wrap {
    .action-wrapper {
      .actions {
        li {
          margin-right: 13px;
        }
      }
    }
    .email-right-aside {
      .email-content {
        .email-wrapper {
          .attachment {
            ul {
              li {
                img {
                  width: 65px;
                  height: 65px;
                }
              }
            }
          }
        }
      }
      .email-top {
        .dropdown-menu {
          left: -58px;
        }
      }
    }
  }
  // mega-menu
  .mega-menu-p {
    padding: 0 23px !important;
  }
  // according
  .default-according {
    .card {
      .card-header {
        i {
          font-size: 14px;
          top: 24px;
        }
      }
      .btn-link {
        display: block;
        width: 100%;
        padding-left: 17px;
      }
    }
  }
  //rating
  .star-ratings {
    .stars {
      .title {
        line-height: 1;
      }
    }
  }
  // tour page
  .user-profile {
    .hovercard {
      .social-media {
        a {
          padding: 0 12px;
        }
      }
    }
  }

  // touchspin page
  .bootstrap-touchspin {
    .touchspin-vertical-tab {
      .input-group {
        width: 93% !important;
      }
    }
  }
  // chat page
  .chat-box {
    .chat-right-aside {
      flex: 0 0 96%;
      max-width: 96%;
      .chat {
        .chat-message {
          width: calc(100% - 20px);
        }
      }
    }
  }
  // error 400 page
  .error-wrapper {
    .error-heading {
      .headline {
        font-size: 100px;
      }
    }
  }
  // gallery page
  .lg-toolbar {
    .lg-icon {
      width: 40px;
    }
  }
  //modal footer
  .modal-footer {
    display: block;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) {
  .product-wrapper-grid {
    &.list-view {
      .col-sm-6 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  .tabbed-card {
    ul {
      right: 0px;
      left: unset;
    }
  }
}
@media only screen and (max-width: 992px) {
  .card {
    .card-body {
      form {
        &.animated-modal {
          display: flex;
          .animated-modal-md-mb {
            margin-bottom: 20px !important;
          }
        }
      }
    }
  }
  .theme-form {
    &.form-inline {
      display: flex;
    }
  }
}

/**=====================
     62. Responsive CSS Ends
==========================**/
